<!-- 调拨 -->
<template>
  <div class="pagePadding">
    <template v-if="!disblesImg">
      <Form inline class="formMarginBtm20 clearfix">
        <FormItem>
          <span>发起仓库：</span>
          <Select placeholder="请选择" v-model="queryFrom.from_warehouse_id" clearable class="iviewIptWidth250">
            <Option v-for="item in storeList" :value="item.warehouse_id" :key="item.warehouse_id">{{ item.warehouse_name }}</Option>
          </Select>
        </FormItem>
        <FormItem class="marginLeft40">
          <span>调拨仓库：</span>
          <Select placeholder="请选择" v-model="queryFrom.to_warehouse_id" clearable class="iviewIptWidth250">
            <Option v-for="item in storeList" :value="item.warehouse_id" :key="item.warehouse_id" :disabled="item.warehouse_id == queryFrom.from_warehouse_id">{{ item.warehouse_name }}</Option>
          </Select>
        </FormItem>
        <FormItem class="marginLeft40">
          <span>调拨时间：</span>
          <DatePicker :editable="false" type="daterange" placeholder="请选择" format="yyyy-MM-dd" class="iviewIptWidth250" @on-change="changeTime"></DatePicker>
        </FormItem>
        <FormItem class="marginLeft40">
          <span>产品编码：</span>
          <Input placeholder="请输入" class="iviewIptWidth250" v-model.trim="queryFrom.product_model_code" />
        </FormItem>
        <FormItem>
          <span>产品名称：</span>
          <Select placeholder="请选择" clearable filterable class="iviewIptWidth250 marginRight40" v-model="queryFrom.product_name" @on-change="productChange">
            <Option v-for="(item, index) in productList" :value="item.product_name" :key="index">{{ item.product_name }}</Option>
          </Select>
        </FormItem>
        <FormItem>
          <span>规格型号/SKU：</span>
          <Select class="iviewIptWidth250 marginRight40" multiple filterable clearable v-model="queryFrom.specification_id_str" :max-tag-count="queryFrom.specification_id_str.length == 1 ? 1 : 0">
            <Option v-for="(item, index) in modelList" :value="item.model_name" :key="index" :label="item.model_name + '-' + item.item_number">
              <span>{{ item.model_name }}</span>
              <span style="float: right; color: #ccc; margin-right: 20px;">{{ item.item_number }}</span>
            </Option>
          </Select>
        </FormItem>
        <FormItem class="marginLeft40">
          <span>批号：</span>
          <Input placeholder="请输入" class="iviewIptWidth250" v-model.trim="queryFrom.batch_number" />
        </FormItem>
        <FormItem class="po-create-number marginRight0" :label-width="10">
          <span class="pageBtn finger btnSure" @click="query">查询</span>
          <span class="longTextBtn finger btnReset marginLeft20" @click="byAllocating = true">发起调拨</span>
        </FormItem>
      </Form>
      <Table :productList="listColumns" :productData="listData" :total="total" :pages="queryFrom" @change-page="changePage" :isLoad="isLoad" :loading="isLoad" totalText="条记录" :rowClass="rowClassName">
        <div slot="summary" class="summary" v-if="listData.length > 0">
          总数量：<span class="color389">{{ totalNum }}</span> 合计金额：<span class="color389">¥{{ totalMoney }}</span
          >元
        </div>
      </Table>
      <!-- <Table :columns="listColumns" border :data="listData" totalText="条记录" highlight-row :row-class-name="rowClassName" :loading="loading"> </Table>
      <div slot="summary" class="summary" v-if="listData.length > 0">
        总数量：<span class="color389">{{ totalNum }}</span> 合计金额：<span class="color389">¥{{ totalMoney }}</span
        >元
      </div>
      <div class="pages" v-if="listData !== null && listData.length > 0 && total">
        <Page v-if="queryFrom && total <= 60" :total="total" :current="queryFrom.page" @on-change="changePage" class="fl" />
        <Page v-if="queryFrom && total > 60" :total="total" :current="queryFrom.page" @on-change="changePage" show-elevator class="fl" />
        <div class="fr totalFont" v-if="total">共计{{ total }}条记录</div>
      </div> -->
    </template>

    <div class="disableImage" v-if="disblesImg">
      <div class="disableDiv">
        <img src="../../assets/images/disableImg.png" alt="" />
        <span>当月已关账，不可操作库存</span>
      </div>
    </div>
    <!-- 发起调拨 -->
    <Modal v-model="byAllocating" :mask-closable="false" width="350" class="modal">
      <p slot="header" class="header">调拨类型</p>
      <RadioGroup vertical size="large" v-model="genre">
        <Radio label="1">同价调拨</Radio>
        <Radio label="2">变价调拨</Radio>
      </RadioGroup>
      <div slot="footer" class="footer">
        <span class="pageBtn finger btnSure" @click="allocating">确认</span>
        <span class="pageBtn finger btnReset" @click="byAllocating = false">取消</span>
      </div>
    </Modal>
    <!--    审核-->
    <Modal v-model="reviewStatus" label-position="left" width="400" :footer-hide="true" class-name="vertical-center-modal">
      <div class="reviewDiv">
        <p>订单是否审核通过？</p>
        <div>
          <span class="pageBtn finger btnSure" @click="approved(1)">通过</span>
          <span class="pageBtn finger btnCancle" @click="approved(0)">不通过</span>
        </div>
      </div>
    </Modal>
    <!-- 打印模板 -->
    <Modal v-model="status" title="请选择模版" cancel-text @on-cancel="status = false" width="568">
      <div class="clearfix paddingBot40px">
        <div class="item">
          <span class="itemTitle">模版类型：</span>
          <Select placeholder="请选择" v-model="template_type" filterable class="iviewIptWidth307" @on-change="typeChange">
            <Option v-for="(item, index) in TemplateList" :value="item.typeID" :key="index">{{ item.typeName }}</Option>
          </Select>
        </div>
        <div class="item">
          <span class="itemTitle">模版名称：</span>
          <Select placeholder="请选择" v-model="template_id" filterable class="iviewIptWidth307" :disabled="!template_type">
            <Option v-for="(item, index) in typeList" :value="item.template_id" :key="index">{{ item.template_name }}</Option>
          </Select>
        </div>
        <!-- <div class="item">
          <span class="itemTitle">每页行数：</span>
          <InputNumber  :min="0" :precision="0" v-model="paging" :active-change="false"  class="iviewIptWidth307"></InputNumber>
        </div> -->
      </div>
      <div slot="footer">
        <span class="pageBtn finger btnSure" @click="surePrint">确定</span>
      </div>
    </Modal>
    <tips-component showModal="delete" v-if="tipStatus" @cancleBtn="tipStatus = false" @sureBrn="sureDelete"></tips-component>
    <!-- 反冲确认-弹窗 -->
    <Modal v-model="isConfirm[0]" label-position="left" width="400" :footer-hide="true" :mask-closable="false" :closable="false" class-name="vertical-center-modal">
      <div class="topModal clearfix">
        <img src="@/assets/images/tipsPostImg.png" class="tipsImg fl" />
        <div class="flDiv fl">
          <p>反冲</p>
          <p>是否确认反冲</p>
        </div>
      </div>
      <div class="foot">
        <span class="pageBtn finger btnCancle" @click="isConfirm = [false, undefined]">取消</span>
        <span class="pageBtn finger marginLeft20 btnSure" @click="recoil(isConfirm[1])">确定</span>
      </div>
    </Modal>
    <!-- 删除确认-弹窗 -->
    <Modal v-model="deleteStatus" label-position="left" width="400" :footer-hide="true" :mask-closable="false" :closable="false" class-name="vertical-center-modal">
      <div class="topModal clearfix">
        <img src="@/assets/images/tipsPostImg.png" class="tipsImg fl" />
        <div class="flDiv fl">
          <p>删除</p>
          <p>是否确认删除此调拨单</p>
        </div>
      </div>
      <div class="foot">
        <span class="pageBtn finger btnCancle" @click="deleteStatus = false">取消</span>
        <span class="pageBtn finger marginLeft20 btnSure" @click="deleteAllocate">确定</span>
      </div>
    </Modal>
  </div>
</template>

<script>
import Table from '@/components/table'
import TipsComponent from '@/components/tipsComponent'
export default {
  name: 'allocate',
  components: {
    Table,
    TipsComponent,
  },
  computed: {
    totleMoney() {
      let totle = 0
      this.listData.forEach(item => {
        totle += item.money * item.num
      })
      return totle
    },
  },
  data() {
    return {
      productList: [],
      modelList: [],
      disblesImg: false,
      TemplateList: [
        { typeName: '采购单', typeID: 1 },
        { typeName: '收货验收单', typeID: 2 },
        { typeName: '入库单', typeID: 3 },
        { typeName: '销售单', typeID: 4 },
        { typeName: '拣货单', typeID: 5 },
        { typeName: '出库单', typeID: 6 },
        { typeName: '采购记录', typeID: 7 },
        { typeName: '收货验收记录', typeID: 8 },
        { typeName: '入库记录', typeID: 9 },
        { typeName: '销售记录', typeID: 10 },
        { typeName: '拣货记录', typeID: 11 },
        { typeName: '出库记录', typeID: 12 },
        { typeName: '调拨单', typeID: 13 },
      ],
      template_id: '',
      template_type: 13,
      clickId: '',
      typeList: [],
      status: false,
      tipStatus: false,
      // 反冲确认弹窗 + 入库单id
      isConfirm: [false, undefined],
      // 调拨类型
      reviewStatus: false,
      totalNum: 0,
      totalMoney: 0,
      genre: '',
      byAllocating: false,
      listColumns: [
        {
          title: '序号',
          type: 'index',
          align: 'center',
          width: 65,
        },
        {
          title: '调拨单号',
          key: 'order_number',
          align: 'center',
          minWidth: 130,
          // maxWidth: 170,
        },
        {
          title: '发起仓库',
          key: 'from_warehouse_name',
          align: 'center',
          minWidth: 120,
          maxWidth: 150,
        },
        {
          title: '调拨仓库',
          key: 'warehouse_name',
          align: 'center',
          minWidth: 120,
          maxWidth: 150,
        },
        {
          title: '调拨类型',
          key: 'transfer_type_str',
          align: 'center',
          minWidth: 120,
          maxWidth: 150,
        },
        {
          title: '产品',
          key: 'product_list',
          align: 'center',
          // minWidth: 200,
          minWidth: 250,
          render: (h, param) => {
            return h('div', [
              h(
                'div',
                {
                  class: 'TextAlign_lf',
                },
                param.row.product_list
              ),
            ])
          },
        },
        {
          title: '数量',
          key: 'total_number',
          align: 'center',
          width: 100,
        },
        {
          title: '金额',
          align: 'center',
          width: 130,
          render: (h, param) => {
            return h('div', [h('span', param.row.total_amount ? '¥' + param.row.total_amount : '0')])
          },
        },
        {
          title: '调拨时间',
          key: 'createTime',
          align: 'center',
          minWidth: 180,
          maxWidth: 180,
        },
        {
          title: '回单',
          key: 'note',
          align: 'center',
          minWidth: 80,
          maxWidth: 100,
          render: (h, param) => {
            let is_back_proof = param.row.is_back_proof;
            return h('div', [
              h(
                'span',
                {
                  style: {
                    color: is_back_proof == 1 ? '#56dd6e' : '#fa3e3b',
                    cursor: 'pointer'
                  },
                  on: {
                    click: (is_back_proof) => {
                      this.up_is_back_proof(param)
                    },
                  },
                },
                is_back_proof == 1?'是':'否'
              ),
            ])
          },
        },
        {
          title: '操作人',
          key: 'user_name',
          align: 'center',
          minWidth: 80,
        },
        {
          title: '打印次数',
          key: 'prints_num',
          align: 'center',
          minWidth: 80,
        },
        {
          title: '操作',
          align: 'center',
          minWidth: 130,
          maxWidth: 180,
          fixed: 'right',
          render: (h, param) => {
            let statu = param.row.transfer_state // -1=待审核 0=审核驳回 1=审核通过
            if (statu == '1') {
              return h('div', [
                h(
                  'span',
                  {
                    style: {
                      color: '#389AFC',
                      display: 'inline-block',
                      cursor: 'pointer',
                    },
                    on: {
                      click: () => {
                        this.goDetails(param.row)
                      },
                    },
                  },
                  '详情'
                ),
                h(
                  'span',
                  {
                    style: {
                      color: '#389AFC',
                      marginLeft: '20px',
                      display: 'inline-block',
                      cursor: 'pointer',
                    },
                    on: {
                      click: () => {
                        if (this.disblesImg) {
                          this.$Message.warning('当月已关账，不可操作库存')
                          return
                        }
                        this.isConfirm = [true, param.row.id]
                      },
                    },
                  },
                  '反冲'
                ),
                h(
                  'span',
                  {
                    style: {
                      color: '#389AFC',
                      marginLeft: '20px',
                      cursor: 'pointer',
                    },
                    on: {
                      click: () => {
                        this.clickId = param.row.id
                        this.status = true
                        this.getTemplate()
                      },
                    },
                  },
                  '打印'
                ),
              ])
            } else if (statu == '-1' || statu == '0') {
              return h('div', [
                h(
                  'span',
                  {
                    style: {
                      color: '#389AFC',
                      display: param.row.apply_order_code && statu == '-1' ? 'inline-block' : 'none',
                      cursor: 'pointer',
                    },
                    on: {
                      click: () => {
                        this.getEdit(param.row)
                      },
                    },
                  },
                  '编辑'
                ),
                h(
                  'span',
                  {
                    style: {
                      color: '#389AFC',
                      display: param.row.apply_order_code && statu == '-1' ? 'inline-block' : 'none',
                      cursor: 'pointer',
                      marginLeft: '20px',
                    },
                    on: {
                      click: () => {
                        // this.deleteAllocate(param.row)
                        this.deleteStatus = true
                        // 调拨单编号
                        this.deOrder_number = param.row.order_number
                      },
                    },
                  },
                  '删除'
                ),
                h(
                  'span',
                  {
                    style: {
                      color: '#389AFC',
                      display: !param.row.apply_order_code ? 'inline-block' : 'none',
                      cursor: 'pointer',
                    },
                    on: {
                      click: () => {
                        this.goDetails(param.row)
                      },
                    },
                  },
                  '详情'
                ),
                h(
                  'span',
                  {
                    style: {
                      color: '#389AFC',
                      marginLeft: '20px',
                      cursor: 'pointer',
                      display: param.row.apply_order_code && statu != '-1' ? 'inline-block' : 'none',
                    },
                    on: {
                      click: () => {
                        if (this.disblesImg) {
                          this.$Message.warning('当月已关账，不可操作库存')
                          return
                        }
                        this.isConfirm = [true, param.row.id]
                      },
                    },
                  },
                  '反冲'
                ),
                h(
                  'span',
                  {
                    style: {
                      color: '#389AFC',
                      marginLeft: '20px',
                      display: !param.row.apply_order_code ? 'inline-block' : 'none',
                      cursor: 'pointer',
                    },
                    on: {
                      click: () => {
                        this.clickId = param.row.id
                        this.status = true
                        this.getTemplate()
                      },
                    },
                  },
                  '打印'
                ),
                h(
                  'span',
                  {
                    style: {
                      color: '#389AFC',
                      display: !param.row.apply_order_code ? 'inline-block' : 'none',
                      cursor: 'pointer',
                      marginLeft: '20px',
                    },
                    on: {
                      click: () => {
                        this.deleteRow(param.row)
                      },
                    },
                  },
                  '删除'
                ),
              ])
            } else {
              return h('div', [
                h(
                  'span',
                  {
                    style: {
                      color: 'red',

                      cursor: 'pointer',
                    },
                    on: {
                      click: () => {
                        this.goDetails(param.row)
                      },
                    },
                  },
                  '详情'
                ),
                h(
                  'span',
                  {
                    style: {
                      color: 'red',
                      marginLeft: '20px',
                      cursor: 'pointer',
                    },
                    on: {
                      click: () => {
                        this.clickId = param.row.id
                        this.status = true
                        this.getTemplate()
                      },
                    },
                  },
                  '打印'
                ),
              ])
            }
          },
        },
      ],
      listData: [],
      storeList: [],
      total: 0,
      // 查询参数
      queryFrom: {
        page: 1,
        rows: 10,
        from_warehouse_id: '',
        to_warehouse_id: '',
        create_time_start: '',
        create_time_end: '',
        batch_number: '',
        product_name: '',
        model_name: '',
        specification_id_str: [],
      },
      deOrder_number: '',
      deleteStatus: false,
      loading: true,
      isLoad:true
    }
  },
  activated() {
    this.inventoryLook()
    if (sessionStorage.getItem('updataCache') == 0) {
      this.query()
      sessionStorage.setItem('updataCache', '1')
    }
  },
  created() {
    this.inventoryLook()
  },
  mounted() {
    this.queryStoreList()
    this.query()
    // 产品名称
    this.queryProduct()
    // 规格名称
    this.queryItemnumber()
  },
  methods: {
    // 修改回单
    up_is_back_proof(param){
      console.log(param.row);
      
      let params={
        id:param.row.id,
      }
      if(param.row.is_back_proof==1){
        params.is_back_proof=2;
      }
      if(param.row.is_back_proof==2){
        params.is_back_proof=1;
      }
      this.$http.put(this.$api.warehouseTransferBackProof, params, false).then(res => {
        if(res.status){
          this.listData.map(item=>{
            if(item.id==params.id){
              if(param.row.is_back_proof==1){
                item.is_back_proof=2;
              }
              if(param.row.is_back_proof==2){
                item.is_back_proof=1;
              }
            }
          })
        }
        console.log('更新成功');
        //this.queryList(true);
      })
    },
    // 查询产品列表和sku列表
    queryProduct(id = null, subId = null) {
      this.$http.get(this.$api.transferProduct, { product_name: subId, warehouse_id: this.warehouse_id }, false).then(res => {
        // if (!subId) {
        this.productList = res.data
        // } else {
        // this.modelList = res.data
        // }
      })
    },
    // 根据仓库查sku
    queryItemnumber(subId) {
      this.$http.get(this.$api.inventoryCodeLookup, { product_id: subId, warehouse_id: this.warehouse_id }, false).then(res => {
        this.modelList = res.data
      })
    },
    // 产品名称改变
    productChange(e) {
      this.queryFrom.specification_id_str = []
      this.modelList = []
      // if (!e) return
      let productObj = this.productList.find(item => item.product_name === e)
      if (productObj) {
        this.queryItemnumber(productObj.product_id)
      } else {
        this.queryItemnumber(null)
      }
    },
    // 新增界面禁用功能
    inventoryLook() {
      this.$http.get(this.$api.inventoryLook, {}).then(res => {
        if (res.data === 1) {
          this.disblesImg = true
        }
      })
    },
    // 销售申请单转调拨的增加删除
    deleteAllocate() {
      this.deleteStatus = false
      this.$http.DeleteAll(this.$api.warehouseTransfer, { order_number: this.deOrder_number }, true).then(res => {
        if (res.status) {
          this.$Message.success('删除成功')
          this.queryList()
        }
      })
    },
    // 反冲成功的行标红
    rowClassName(row, index) {
      if (row.transfer_state == 2) {
        return 'redRow'
      }
      return ''
    },
    // 反冲
    recoil(id) {
      this.$http.get(this.$api.getTransferRecoil, { id: id }, true).then(res => {
        if (res.status) {
          this.$Message.success('反冲成功')
          this.queryList()
        }
      })
      this.isConfirm = [false, undefined]
    },
    // 发送请求获取 模版名称
    typeChange(e) {
      if (!e) return
      this.$http.get(this.$api.templateInfo, { template_type: e }, false).then(res => {
        this.typeList = res.data
      })
    },
    // 获取最近的模板
    getTemplate() {
      // 获取模板名称
      this.$http.get(this.$api.templateInfo, { template_type: this.template_type }, false).then(res => {
        this.typeList = res.data
      })
      // 获取模板记录
      this.$http.get(this.$api.recentTemplate, { template_type: this.template_type }, true).then(res => {
        if (res.data) {
          this.template_id = res.data.template_id
        }
      })
    },
    surePrint() {
      let api_info = `${this.$api.warehouseTransferDetail.url}?id=${this.clickId}`
      // for (let objName in this.queryFrom) {
      //   console.log(objName)
      //   if (this.queryFrom[objName]) {
      //     api_info = api_info + `${objName}=${this.queryFrom[objName]}&`
      //   }
      // }
      if (!this.template_type) {
        this.$Message.warning('请选择模版类型')
        return
      }
      if (!this.template_id) {
        this.$Message.warning('请选择模版名称')
        return
      }
      this.$http.downFile(this.$api.templatePrint, { template_id: this.template_id, api_info: api_info, paging: this.paging }, true).then(res => {
        this.status = false
        this.$Message.success('导出成功')
        let blob = new Blob([res.data], { type: 'application/x-xlsx' })
        const aLink = document.createElement('a')
        aLink.href = URL.createObjectURL(blob)
        aLink.download = '调拨单.xlsx'
        aLink.click()

        this.queryList()
      })
    },
    // 通过审核
    approved(num) {
      let data = {
        id: this.reviewInfo.id,
        check_result: num,
      }
      this.$http.put(this.$api.pruchaseApplyManage, data, true).then(res => {
        this.reviewStatus = false
        this.query()
      })
    },
    // 点击删除
    deleteRow(row) {
      this.reviewInfo = row
      this.tipStatus = true
    },
    // 确认删除
    sureDelete() {
      this.$http.DeleteAll(this.$api.warehouseTransfer, { id: this.reviewInfo.id }, true).then(res => {
        this.query()
        this.tipStatus = false
      })
    },
    // 点击审核
    review(row) {
      this.reviewInfo = row
      this.reviewStatus = true
    },
    // 显示驳回原因
    reviewReson(row) {
      console.log('驳回原因', row)
    },
    // 去调拨编辑
    getEdit(row) {
      this.$router.push({
        path: '/addAllocate',
        query: {
          type: '1', // 销售申请单转调拨单
          addType: row.transfer_type,
          id: row.id,
          order_code: row.order_code,
        },
      })
    },
    // 去详情
    goDetails(row) {
      this.$router.push({
        path: `/allocateDetail`,
        query: {
          id: row.id,
          addType: row.transfer_type,
        },
      })
    },
    // 查询列表
    queryList() {
      this.loading = true
      this.isLoad = true
      this.queryFrom.model_name = this.queryFrom.specification_id_str ? this.queryFrom.specification_id_str.join(',') : null
      this.$http.get(this.$api.warehouseTransfer, this.queryFrom, true).then(res => {
	this.isLoad = false
        this.loading = false
        this.listData = res.data
        this.totalNum = res.total_number
        this.totalMoney = res.total_amount
        this.total = res.total
        for (let i = 0; i < this.listData.length; i++) {
          this.listData[i].createTime = this.listData[i].create_time ? this.$moment(this.listData[i].create_time * 1000).format('YYYY-MM-DD HH:mm:ss') : ''
        }
      })
    },
    query() {
      this.queryFrom.page = 1
      this.queryList()
    },
    // 时间改变
    changeTime(e) {
      if (e[0] == '' && e[1] == '') {
        this.queryFrom.create_time_start = null
        this.queryFrom.create_time_end = null
      } else {
        this.queryFrom.create_time_start = e[0]
        this.queryFrom.create_time_end = e[1]
      }
    },
    // 查询仓库列表
    queryStoreList() {
      let data = {
        warehouse_name: '',
        page: 1,
        rows: 1000,
      }
      this.$http.get(this.$api.permissionWarehouseList, data, true).then(res => {
        this.storeList = res.data
      })
    },
    changePage(e) {
      this.queryFrom.page = e
      this.queryList()
    },
    allocating() {
      if (!this.genre) {
        this.$Message.warning('请选择调拨类型')
      } else {
        this.byAllocating = false
        sessionStorage.setItem('updataCache', '0')
        this.$router.push({
          path: `/addAllocate`,
          query: {
            change: 1,
            addType: this.genre, // 1是同价调拨 2是变价
          },
        })
      }
    },
  },
}
</script>

<style scoped lang="less">
.item {
  margin: 20px;
}
.summary > span:first-child {
  margin-right: 30px;
}

.modal {
  /deep/ .ivu-modal-body {
    text-align: center;
  }

  /deep/ .ivu-modal-footer {
    border: 0;
  }

  /deep/ .ivu-radio-wrapper {
    margin: 10px;
  }

  .header {
    font-size: 18px;
    font-weight: 800;
  }

  .footer {
    display: flex;
    justify-content: space-around;

    .pageBtn {
      width: 110px;
    }
  }
}
// 反冲确认弹窗
.foot {
  text-align: right;
}
.topModal {
  padding-bottom: 56px;
  margin-top: 14px;
  .tipsImg {
    width: 36px;
    height: 36px;
    margin: 0 13px 0 9px;
  }
  .flDiv {
    p:nth-child(1) {
      display: block;
      height: 24px;
      font-size: 18px;
      font-weight: 600;
      color: rgba(51, 51, 51, 1);
      line-height: 24px;
      margin-bottom: 7px;
    }
    p:nth-child(2) {
      height: 20px;
      font-size: 14px;
      font-weight: 400;
      color: #666666;
      line-height: 20px;
    }
  }
}
</style>
